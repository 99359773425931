import * as React from 'react';
import styled from 'styled-components';
import {
  ButtonGroup,
  Text,
  ThemeProvider,
  Button,
  Layout,
  audiDarkTheme,
  audiLightTheme,
} from '@audi/audi-ui-react';
import type { Theme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2, useContent } from '@oneaudi/feature-app-utils';

import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils/cjs/LayerContentHTML';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import type {
  BasicTeaserContent,
  BasicTeaserCtaButton,
  BasicTeaserServices,
  FeatureAppMeta,
} from '../../types';
import { LegalInfo } from './LegalInfo';
import { isDebugMode } from '../utils';
import { APP_ID } from '../../environment';
import mapContent, { EditorJsonContent, FalconContent } from '../contentMapping/contentMapping';
import { LayerManagerContext } from '../context';
import { setHeadlessUrl } from '../utils/setHeadlessUrl';
import { RichText } from './RichText';
import { Image } from './Image';
import { Video } from './Video';
import {
  TrackingContextProvider,
  useImpressionTrackingRef,
  useTrackClick,
} from '../context/TrackingContext';

interface StyledContainerProps {
  mediaPosition?: BasicTeaserContent['asset']['mediaPosition'];
  legalInfoDisplay?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  margin: auto;
  background-color: var(${({ theme }: { theme: Theme }) => theme.colors.background.level[0]});
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'media'
    'textarea';

  padding: ${({ theme }: { theme: Theme }) =>
    `var(${theme.responsive.spacing.xxl}) var(${theme.responsive.spacing.m})`};

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.s}px) {
    padding-inline: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.xl});
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    padding-inline: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.xxl});
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    ${({ mediaPosition }: StyledContainerProps) =>
      mediaPosition === 'right'
        ? `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textarea media";
    `
        : `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "media textarea";
    `};
    padding-inline: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.xxxl});
    padding-block-end: ${({ theme, legalInfoDisplay }: StyledContainerProps & { theme: Theme }) =>
      !legalInfoDisplay ? `var(${theme.responsive?.spacing.xxl})` : ''};
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    ${({ mediaPosition }: StyledContainerProps) =>
      mediaPosition === 'right'
        ? `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textarea media";
    `
        : `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "media textarea";
    `};
    padding-block-end: ${({ theme, legalInfoDisplay }: StyledContainerProps & { theme: Theme }) =>
      !legalInfoDisplay ? `var(${theme.responsive?.spacing.xxl})` : ''};
  }
`;

interface StyledMediaProps {
  aspectRatio?: BasicTeaserContent['asset']['aspectRatio'];
  mediaPosition?: BasicTeaserContent['asset']['mediaPosition'];
}

const StyledMedia = styled.div<StyledMediaProps>`
  grid-area: media;
  position: relative;

  .div {
    @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.screen-size-xs {
      @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.s - 1}px) {
        display: block;
      }
    }

    &.screen-size-s {
      @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.s}px) and (max-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.m - 1}px) {
        display: block;
      }
    }

    &.screen-size-m {
      @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.m}px) and (max-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.l - 1}px) {
        display: block;
      }
    }

    &.screen-size-l {
      @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.l}px) and (max-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.xl - 1}px) {
        display: block;
      }
    }

    &.screen-size-xl {
      @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.xl}px) and (max-width: ${({ theme }: { theme: Theme }) =>
          theme.breakpoints.xxl - 1}px) {
        display: block;
      }
    }

    &.screen-size-xxl {
      @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
        display: block;
      }
    }
  }
`;

const StyledImage = styled(StyledMedia)`
  height: 0;
  overflow: hidden;
  padding-top: ${({ aspectRatio }) => (aspectRatio === '1:1' ? '100' : '56.25')}%;

  .image__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
`;

const StyledTextArea = styled.div`
  sup {
    position: initial;
    line-height: initial;
  }

  grid-area: textarea;
  align-self: center;

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xs}px) {
    padding-block-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxl})`};
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-inline-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxl})`};
    padding-inline-end: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.xxl})`};
    padding-block-start: 0;
  }

  .subheading {
    margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l})`};
  }
  .text {
    margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.m})`};
  }
  .buttons {
    margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l})`};
  }
  > div:first-child {
    margin-block-start: 0;
  }
`;

export const BasicTeaser = ({
  themeColor,
  heading,
  headingTag,
  subHeading,
  text,
  buttons,
  consumptionAndEmissions,
  disclaimers,
  supplier,
  vueFormatterService,
  localeService,
  meta,
  asset,
}: BasicTeaserContent &
  BasicTeaserServices & { meta: FeatureAppMeta }): React.ReactElement | null => {
  const impressionTrackingRef = useImpressionTrackingRef();
  const trackClick = useTrackClick();
  const hasLegalInfo = !!consumptionAndEmissions?.length || !!disclaimers?.length;
  const { aspectRatio = '1:1', mediaPosition = 'left' } = asset;
  const subHeadingTag =
    // SEO optimization: subHeading tag is one hierarchy level lower than heading
    (headingTag ? `h${parseInt(headingTag.replace(/\D/g, '') || '2', 10) + 1}` : 'h3') as
      | 'h3'
      | 'h4'
      | 'h5';
  const legalInfo = hasLegalInfo ? (
    <LegalInfo
      mediaPosition={mediaPosition}
      consumptionAndEmissions={consumptionAndEmissions}
      disclaimers={disclaimers}
      vueFormatterService={vueFormatterService}
      localeService={localeService}
    />
  ) : null;
  const layerManager = React.useContext(LayerManagerContext);

  const handleOpenInLayer = React.useCallback((url: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    layerManager?.openFocusLayer(
      () => <LayerContentHTML enableDeepLinking url={`${setHeadlessUrl(url)}`} />,
      {},
      {
        userCloseable: true,
        size: FocusLayerSizeV2.A,
      },
    );
  }, []);

  const shouldRenderButtons = () => {
    const isValidButton = (button: BasicTeaserCtaButton) =>
      button?.label && (button?.url || button?.openInTabOrLayer === 'chatbot');

    return buttons && buttons.some(isValidButton);
  };

  return (
    <ThemeProvider theme={themeColor === 'dark' ? audiDarkTheme : audiLightTheme}>
      <StyledContainer
        ref={impressionTrackingRef}
        mediaPosition={mediaPosition}
        data-testid="basic-teaser"
        legalInfoDisplay={hasLegalInfo}
      >
        {asset.assetType === 'image' && (
          <UeContainer propertyPath="asset" label="images">
            <UeReference propertyPath="asset" behavior="component">
              {(ueProps) => (
                <StyledImage aspectRatio={aspectRatio} mediaPosition={mediaPosition} {...ueProps}>
                  <Image asset={asset} />
                </StyledImage>
              )}
            </UeReference>
          </UeContainer>
        )}
        {asset.assetType === 'video' && (
          <UeContainer propertyPath="asset" label="videos">
            <UeReference propertyPath="asset" behavior="component">
              {(ueProps) => (
                <StyledMedia aspectRatio={aspectRatio} mediaPosition={mediaPosition} {...ueProps}>
                  <UeElement type="media" property="assetSrc" label="video">
                    <Video asset={asset} meta={meta} />
                  </UeElement>
                </StyledMedia>
              )}
            </UeReference>
          </UeContainer>
        )}

        <StyledTextArea>
          {heading && (
            <div>
              <UeElement type="text" property="heading" label="Headline">
                {(ueProps) => (
                  <Text variant="order2" as={headingTag || 'h2'} {...ueProps}>
                    {renderTextWithFootnotesReferencesV2(heading)}
                  </Text>
                )}
              </UeElement>
            </div>
          )}
          {subHeading && (
            <div className="subheading">
              <UeElement type="text" property="subHeading" label="Subline">
                {(ueProps) => (
                  <Text variant="order3" as={subHeadingTag} {...ueProps}>
                    {renderTextWithFootnotesReferencesV2(subHeading)}
                  </Text>
                )}
              </UeElement>
            </div>
          )}
          {text && (
            <div className="text">
              <UeElement type="text" property="text" label="Copy">
                {(ueProps) => <RichText text={text} {...ueProps} />}
              </UeElement>
            </div>
          )}
          {shouldRenderButtons() && (
            <div className="buttons">
              <ButtonGroup spaceStackStart="l" spaceStackEnd="xl" variant="block-buttons">
                {buttons?.map(
                  (
                    { variant = 'primary', label, url, ariaLabel, openInTabOrLayer, chatBotId },
                    i,
                  ) => {
                    if (label && openInTabOrLayer === 'chatbot') {
                      return (
                        <Button
                          variant={variant}
                          key={label}
                          aria-label={ariaLabel}
                          onClick={() => {
                            trackClick?.({
                              pos: i + 1,
                              targetURL: url,
                              label,
                              action: 'content',
                              eventNameSuffix: 'open chatbot',
                            });
                            window.acquireIO.max('text', chatBotId, null, false, 'bot');
                          }}
                        >
                          <UeElement
                            type="text"
                            property={i === 0 ? 'firstButtonLabel' : 'secondButtonLabel'}
                            label="Label"
                          >
                            {label}
                          </UeElement>
                        </Button>
                      );
                    }
                    if (label && url) {
                      return (
                        <Button
                          variant={variant}
                          key={label}
                          aria-label={ariaLabel}
                          newWindow={openInTabOrLayer === 'tab'}
                          href={openInTabOrLayer === 'layer' ? undefined : url}
                          onClick={() => {
                            trackClick?.({
                              pos: i + 1,
                              targetURL: url,
                              label,
                              action: openInTabOrLayer === 'layer' ? 'content' : undefined,
                              eventNameSuffix:
                                openInTabOrLayer === 'layer' ? 'layer open' : undefined,
                            });

                            if (openInTabOrLayer === 'layer') {
                              handleOpenInLayer(url);
                            }
                          }}
                        >
                          <UeElement
                            type="text"
                            property={i === 0 ? 'firstButtonLabel' : 'secondButtonLabel'}
                            label="Label"
                          >
                            {label}
                          </UeElement>
                        </Button>
                      );
                    }
                    return null;
                  },
                )}
              </ButtonGroup>
            </div>
          )}
          {hasLegalInfo && legalInfo}
          {supplier?.supplierName && supplier?.supplierLink.label && supplier?.supplierLink.url && (
            <Layout spaceStackStart="m">
              <UeElement type="text" property="supplierName" label="Supplier Name">
                {(ueProps) => (
                  <Text as="span" variant="copy2" spaceInlineEnd="m" {...ueProps}>
                    {supplier.supplierName}
                  </Text>
                )}
              </UeElement>
              <UeElement type="text" property="supplierLinkLabel" label="Supplier Name">
                {(ueProps) => (
                  <Text as="p" variant="copy2" {...ueProps}>
                    <a
                      href={supplier.supplierLink.url}
                      aria-label={supplier.supplierLink.ariaLabel}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {supplier.supplierLink.label}
                    </a>
                  </Text>
                )}
              </UeElement>
            </Layout>
          )}
        </StyledTextArea>
      </StyledContainer>
    </ThemeProvider>
  );
};

export const BasicTeaserContentLoader = ({
  initialContent,
  localeService,
  vueFormatterService,
  trackingService,
  meta,
}: {
  meta: FeatureAppMeta;
  initialContent?: BasicTeaserContent;
} & BasicTeaserServices): React.ReactElement | null => {
  // eslint-disable-next-line
  const rawContent = useContent<BasicTeaserContent>() || initialContent;
  const content = mapContent(rawContent as EditorJsonContent | FalconContent);

  if (isDebugMode())
    // eslint-disable-next-line no-console
    console.debug(`${APP_ID} ->`, {
      content,
    });

  if (!content) return null;

  return (
    <TrackingContextProvider trackingService={trackingService} heading={content.heading}>
      <BasicTeaser
        {...content}
        localeService={localeService}
        vueFormatterService={vueFormatterService}
        meta={meta}
      />
    </TrackingContextProvider>
  );
};
